import { defaultTheme, Theme } from "@jamiegardiner/cosmos-theme"

export const theme: Theme = {
  ...defaultTheme,
  fonts: {
    heading: "'Lato', sans-serif",
    body: "'Lato', sans-serif",
  },
  fontSizes: [12, 16, 24, 36, 48, 60, 72],
  lineHeights: {
    heading: "5rem",
    body: "normal",
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  colors: {
    ...defaultTheme.colors,
    background: "#154192",
    primary: "#071843",
  },
  shadows: {
    small: "0px 2px 2px rgba(0, 0, 0, 0.5)",
    medium: "0px 10px 10px rgba(0, 0, 0, 0.5)",
  },
}
