module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"greatwhiteencounters","short_name":"gwe","start_url":"/","background_color":"#154192","theme_color":"#071843","display":"minimal-ui","icon":"/Users/jamie.gardiner/development/jamiegardiner/greatwhiteencounters.com/src/media/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TLF7KVB","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
