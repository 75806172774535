import { GlobalStyle } from "@jamiegardiner/cosmos-components"
import { ThemeProvider } from "@jamiegardiner/cosmos-system"
import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"

import { theme } from "../utils"

const wrapRootElement = ({ element }: { element: ReactNode }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </>
)

export default wrapRootElement
